import { createActionGroup, props } from '@ngrx/store';
import { SfWe_Plan } from 'src/app/shared/models';

export const EnrollmentSunfireActions = createActionGroup({
  source: 'EnrollmentSunfire',
  events: {
    'Load EnrollmentsSunfire': props<{
      consumerId: string;
      agentId?: string;
    }>(),
    'Load EnrollmentsSunfire Success': props<{
      enrollmentsSunfire: SfWe_Plan[];
    }>(),
    'Load EnrollmentsSunfire Failure': props<{ error: any }>(),
    'Load EnrollmentSunfire': props<{
      enrollmentSunfireId: string;
      consumerId: string;
    }>(),
    'Load EnrollmentSunfire Success': props<{
      enrollmentSunfire: SfWe_Plan;
    }>(),
    'Load EnrollmentSunfire Failure': props<{ error: any }>(),
    // 'Add EnrollmentSunfire': props<{
    //   consumerId: string;
    //   enrollmentSunfire: SfWe_Plan | Partial<SfWe_Plan>;
    // }>(),
    // 'Add EnrollmentSunfire Success': props<{
    //   enrollmentSunfire: SfWe_Plan;
    // }>(),
    // 'Add EnrollmentSunfire Failure': props<{ error: any }>(),
    // 'Remove EnrollmentSunfire': props<{
    //   enrollmentSunfireId: string;
    //   consumerId: string;
    // }>(),
    // 'Remove EnrollmentSunfire Success': props<{
    //   enrollmentSunfireId: string;
    // }>(),
    // 'Remove EnrollmentSunfire Failure': props<{ error: any }>(),
    'Update EnrollmentSunfire': props<{
      consumerId: string;
      enrollmentSunfireId: string;
      enrollmentSunfire: Partial<SfWe_Plan>;
    }>(),
    'Update EnrollmentSunfire Success': props<{
      consumerId: string;
      enrollmentSunfireId: string;
      enrollmentSunfire: Partial<SfWe_Plan>;
    }>(),
    'Update EnrollmentSunfire Failure': props<{ error: any }>(),
  },
});
